<template>
  <AppLayout>
    <Container>
      <router-view name="AsideBar"></router-view>
      <router-view></router-view>
      <notifications position="bottom right" />
    </Container>
  </AppLayout>
</template>

<script>
import Container from "@/containers/Container";

export default {
  name: "App",
  components: {
    Container
  }
}
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
