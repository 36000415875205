import gql from "graphql-tag";

const GET_USER = gql`
  query getUser($id: Int!) {
    getUser(id: $id) {
      id
      first_name
      last_name
      email
      roles {
        id
        title
        slug
      }
      media {
        media {
          path
        }
      }
      description
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($data: UserInput!, $id: Int!) {
    updateUser(data: $data, id: $id) {
      id
      first_name
      last_name
      nickname
      email
      roles {
        id
        title
        slug
      }
      media {
        media {
          path
        }
      }
      description
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword($data: UpdateUserPasswordInput) {
    updatePassword(data: $data) {
      id
    }
  }
`;

export { UPDATE_USER, GET_USER, UPDATE_PASSWORD };
