import gql from "graphql-tag";
import { PRODUCT_FRAGMENT } from "@/graphql/products/fragment";

const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $data: ProductInput!) {
    updateProduct(id: $id, data: $data) {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductInput!) {
    createProduct(data: $data) {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const DELETE_ADDON = gql`
  mutation deleteAddon($id: Int!) {
    deleteAddon(id: $id) {
      id
    }
  }
`;

const UPDATE_POSITION = gql`
  mutation updatePosition($productId: Int!, $medias: [MediaPosition]) {
    updatePosition(product_id: $productId, medias: $medias) {
      media {
        filename
        path
        id
      }
      is_primary
      position
    }
  }
`;

const DELETE_MEDIA = gql`
  mutation deleteMedia($id: Int!, $userId: Int) {
    deleteMedia(id: $id, user_id: $userId) {
      id
    }
  }
`;

const DELETE_PRICE_SETTING = gql`
  mutation deleteSmartPricing($id: Int!) {
    deleteSmartPricing(id: $id)
  }
`;

const SET_PRODUCT_VERIFIED = gql`
  mutation setProductVerified($id: Int!) {
    setProductVerified(id: $id) {
      id
    }
  }
`;

const SET_PRODUCT_DECLINED = gql`
  mutation setProductDeclined($id: Int!) {
    setProductDeclined(id: $id) {
      id
    }
  }
`;

const SET_PRODUCT_UNPUBLISHE = gql`
  mutation setProductUnPublish($id: Int!) {
    setProductUnPublish(id: $id) {
      id
    }
  }
`;

export {
  UPDATE_PRODUCT,
  DELETE_ADDON,
  UPDATE_POSITION,
  DELETE_MEDIA,
  CREATE_PRODUCT,
  DELETE_PRICE_SETTING,
  SET_PRODUCT_VERIFIED,
  SET_PRODUCT_DECLINED,
  SET_PRODUCT_UNPUBLISHE
};
