<template>
  <component :is="container">
    <slot />
  </component>
</template>

<script>
import { shallowRef } from "vue";
import DefaultContainer from "@/containers/DefaultContainer";

const defaultContainer = shallowRef(DefaultContainer);

export default {
  name: "Container",
  data: () => ({
    container: null
  }),
  watch: {
    $route: {
      async handler(route) {
        try {
          const component = await import(`@/containers/${route.meta.container}.vue`);
          const wrappedLayout = shallowRef(component?.default);
          this.container = wrappedLayout.value || defaultContainer;
        } catch (e) {
          this.container = defaultContainer.value;
        }
      },
      immediate: true
    }
  }

};
</script>

<style scoped>

</style>
