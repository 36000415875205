import gql from "graphql-tag";
import { PRODUCT_FRAGMENT } from "@/graphql/products/fragment";

const GET_PRODUCTS = gql`
  query getProducts($userId: Int, $page: Int, $perPage: Int, $sortCreatedAt: Int, $status: SortProductStatus, $search: String) {
    getProducts(
      userId: $userId
      page: $page
      perPage: $perPage
      sortCreatedAt: $sortCreatedAt
      status: $status
      search: $search
    ) {
      total
      items {
        id
        parent_id
        user_id
        start_price
        country
        address
        reviews_count
        categories {
          id
          translation {
            title
          }
        }
        verified_at
        declined_at
        created_at
        rating
        default_price
        currency
        translation {
          id
          product_id
          locale
          full_description
          small_description
          title
        }
        media {
          is_primary
          position
          media {
            path
          }
        }
        urlm {
          title
          url
        }
        status
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query getProduct($id: Int!) {
    getProduct(id: $id) {
      ...Product
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const GET_UNAVAILABLE_DAYS = gql`
  query getUnavailableDays($product_id: Int!, $considerPreparationBefore: Boolean) {
    getUnavailableDays(product_id: $product_id, considerPreparationBefore: $considerPreparationBefore) {
      id
      dates {
        from
        to
        checkInDay
        checkOutDay
        only
      }
    }
  }
`;

const GET_SETTINGS_INFO = gql`
  query getSettingsInfo($product_id: Int!) {
    getVerifiedUsers {
      id
      first_name
      last_name
      email
      created_at
      verified_at
      declined_at
      roles {
        slug
      }
      media {
        media {
          path
          filename
          filesize
        }
      }
    }
    getLabels {
      id
      color
      translation {
        title
      }
      translations {
        title
      }
    }
    getHost(product_id: $product_id) {
      first_name
      last_name
      description
      media {
        media {
          id
          path
          filename
        }
      }
    }
  }
`;

const SET_PRODUCT_UNPUBLISHE = gql`
  mutation setProductUnPublish($id: Int!) {
    setProductUnPublish(id: $id) {
      id
    }
  }
`;

export { GET_PRODUCTS, GET_PRODUCT, GET_UNAVAILABLE_DAYS, GET_SETTINGS_INFO, SET_PRODUCT_UNPUBLISHE };
