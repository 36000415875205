import Cookies from "js-cookie";
import { CHECK_AUTH } from "@/graphql/auth";
import { apolloClient } from "@/plugins/apollo/config";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import { UPDATE_USER } from "@/graphql/user";
import { notify } from "@kyvg/vue3-notification";

export const auth = {
  namespaced: true,
  state: () => ({
    user: {
      nickname: "",
      firstName: "",
      lastName: "",
      email: "",
      imageUrl: "",
      role: "",
      id: null,
      media: null
    },
    userLoading: false,
    isUserUpdating: false
  }),
  getters: {
    isAdmin(state) {
      return state.user.role === "admin";
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload.user;
    },
    setLoading(state, payload) {
      state.userLoading = payload;
    },
    setUpdating(state, payload) {
      state.isUserUpdating = payload;
    }
  },
  actions: {
    async getUser(context) {
      context.commit("setLoading", true);
      await apolloClient
        .query({
          query: CHECK_AUTH,
          fetchPolicy: "network-only"
        })
        .then((data) => {
          const {
            data: {
              me: { id, first_name: firstName, last_name: lastName, nickname, email, roles, media, description }
            }
          } = data;

          const user = {
            id,
            nickname,
            firstName,
            lastName,
            email,
            imageUrl: "",
            role: roles[0].slug,
            role_id: roles[0].id,
            media,
            description
          };

          context.commit("setUser", { user });
        })
        .catch(() => {
          notify({
            title: "Problem with login",
            type: "error"
          });
          Cookies.remove("auth_session");
          router.replace("/login");
        })
        .finally(() => {
          context.commit("setLoading", false);
        });
    },
    async updateUser(context, payload) {
      context.commit("setUpdating", true);
      await apolloClient
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: payload.id,
            data: payload.data
          }
        })
        .then(() => {
          context.dispatch("getUser");
        })
        .finally(() => {
          context.commit("setUpdating", false);
        });
    }
  }
};
