<template>
  <div class="min-h-screen bg-gray-50">
    <main class="max-w-6xl mx-auto pb-10 lg:py-12 lg:px-8">
      <div class="grid grid-cols-12 gap-x-5">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "DefaultContainer"
};
</script>

<style scoped>

</style>
