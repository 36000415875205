<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { shallowRef } from 'vue';

export default {
  name: 'AppLayout',
  data: () => ({
    layout: null
  }),
  watch: {
    $route: {
      async handler(route) {
        try {
          const component = await import(`@/layouts/${route.meta.layout}.vue`);
          const wrappedLayout = shallowRef(component?.default);
          this.layout = wrappedLayout.value;
        } catch (e) {
          const component = await import(`@/layouts/AppLayoutDefault`);
          const wrappedLayout = shallowRef(component?.default);
          this.layout = wrappedLayout.value;
        }
      }
    }
  }
};
</script>
