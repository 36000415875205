import gql from 'graphql-tag';

const LOGIN = gql`mutation Login($data: LoginInput!) {
    login(data: $data) {
        access_token
      }
    }`;

const CHECK_AUTH = gql`
  query me {
    me {
      id
      first_name
      last_name
      email
      nickname
      roles {
        id
        title
        slug
      }
      media {
        media {
          path
        }
      }
      description
    }
  }
`


export {
  LOGIN,
  CHECK_AUTH
}
