import gql from "graphql-tag";

const PRODUCT_FRAGMENT = gql`
  fragment Product on Product {
    id
    created_at
    options {
      id
    }
    medias {
      media {
        filename
        path
        id
      }
      is_primary
      position
    }
    translation {
      id
      product_id
      locale
      full_description
      small_description
      title
    }
    children {
      id
      default_price
      capacity
      max_capacity
      min_booking_duration
      max_booking_duration
      preparation_before_booking
      preparation_after_booking
      translation {
        title
      }
      translations {
        title
      }
      price_settings {
        id
        type
        value
        price
        active
      }
      synchronization {
        id
        product_id
        link
        channel_id
      }
    }
    categories {
      id
      translation {
        title
      }
      media {
        media {
          path
          filename
        }
      }
    }
    addons {
      product_id
      id
      price
      pricing {
        code
        price
      }
      qty
      translation {
        title
        description
      }
    }
    country_ref {
      id
      code
      title
      taxName
      vat
    }
    send_email_in_advance_days
    phone
    email
    country_code
    address
    location
    point
    check_in
    check_out
    currency
    product_currency {
      code
      main
      id
      symbol
    }
    commission
    urlm {
      url
    }
    user_id
    label_id
    emailDescription
    cancellation_policy
  }
`;

export {
  PRODUCT_FRAGMENT
}

