import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";
import { SentryLink } from "apollo-link-sentry";
import { from } from "apollo-boost";

// const errorLink = onError(
//   ({ graphQLErrors, networkError }) => {
//     console.error('networkError', networkError);
//     console.error('graphQLErrors', graphQLErrors);
//
//     if (graphQLErrors && graphQLErrors.length && graphQLErrors[0].message === 'Record not found') {
//       redirect('/404');
//     }
//
//     if (process.client && graphQLErrors && graphQLErrors.length) {
//       graphQLErrors.forEach(err => {
//         app.$toast.error(err.message);
//       });
//     }
//   }

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("auth_session");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "accept-currency": "SEK"
    }
  };
});

const cache = new InMemoryCache();
// eslint-disable-next-line new-cap
const httpLink = new createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT
});

const sentryLink = new SentryLink();

const link = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length && graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
    window.location.href = "/login";
  }
});

const apolloClient = new ApolloClient({
  link: from([authLink, httpLink, sentryLink, link]),
  cache
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
});

export { apolloClient, apolloProvider };

// const apolloProvider = createApolloProvider({
//   defaultClient: apolloClient,
//   defaultOptions: {
//     $query: {
//       loadingKey: 'loading'
//     }
//   },
//   prefetch: false
// });
