import { GET_PRODUCT } from "@/graphql/products/queries";
import { apolloClient } from "@/plugins/apollo/config";
import { UPDATE_POSITION, UPDATE_PRODUCT } from "@/graphql/products/mutations";
import { notify } from "@kyvg/vue3-notification";

const shortId = () => Math.floor(Date.now() / 1000).toString(36);

const initialStateProduct = {
  description: "",
  generalInfo: {
    title: "",
    subtitle: "",
    url: "",
    slug: "",
    created_at: "",
    verified_at: "",
    type: {
      id: 3,
      name: "GoGlamping",
      image: ""
    },
    email: null,
    phone: null,
    currency: {
      code: "EUR",
      id: 1,
      main: true,
      symbol: "€"
    }
  },
  options: [],
  medias: [],
  location: {
    address: "",
    location: "",
    point: ""
  },
  children: [
    {
      id: "create",
      default_price: null,
      max_booking_duration: null,
      min_booking_duration: null,
      preparation_before_booking: null,
      price_settings: [],
      capacity: null,
      max_capacity: null,
      synchronization: []
    }
  ],
  addons: [],
  addressData: {
    country_id: null,
    address: null,
    location: [0, 0]
  },
  cancellationPolicy: "Flexible",
  checkInOut: {
    checkIn: null,
    checkOut: null
  },
  welcomeLatter: "",
  settings: {
    commission: null,
    userId: null,
    labelId: null
  },
  currency: null
};

const initialCreateSteps = [
  {
    field: "generalInfo",
    route: { name: "general-create", hash: "#general" },
    completed: false
  },
  {
    field: "description",
    route: { name: "general-create", hash: "#description" },
    completed: false
  },
  {
    field: "medias",
    route: { name: "general-create", hash: "#gallery" },
    completed: false
  },
  {
    field: "options",
    route: { name: "general-create", hash: "#amenities" },
    completed: false
  },
  {
    field: "addressData",
    route: { name: "general-create", hash: "#location" },
    completed: false
  },
  {
    field: "children-price",
    route: { name: "unit-create", hash: "#pricing" },
    completed: false
  },
  {
    field: "children-trip-length",
    route: { name: "unit-create", hash: "#trip-length" },
    completed: false
  },
  {
    field: "children-guests",
    route: { name: "unit-create", hash: "#guests" },
    completed: false
  },
  {
    field: "addons",
    route: { name: "addons-create" },
    completed: false
  },
  {
    field: "cancellationPolicy",
    route: { name: "policies-create" },
    completed: false
  },
  {
    field: "checkInOut",
    route: { name: "info-for-guests-create", hash: "#check-in-and-out" },
    completed: false
  },
  {
    field: "welcomeLatter",
    route: { name: "info-for-guests-create", hash: "#welcome-letter" },
    completed: false
  },
  {
    field: "complete",
    route: { name: "complete" },
    completed: false
  }
];

export const product = {
  namespaced: true,
  state: () => ({
    isLoadingProduct: false,
    isLoadingMedias: false,
    product: initialStateProduct,
    createStepsCompleted: initialCreateSteps,
    mode: "create",
    currencies: []
  }),
  getters: {
    productOptionsId(state) {
      return state.product.options.map((option) => option.id);
    },
    units(state) {
      return state.product.children.map((child) => ({
        id: child.id,
        title: child.translation.title,
        capacity: child.capacity
      }));
    },
    addons(state) {
      return state.product.addons;
    },
    addressData(state) {
      return {
        countryId: state.product.addressData.countryId,
        address: state.product.addressData.address,
        location: state.product.addressData.location
      };
    },
    getCurrentCurrencyCode(state) {
      return state.product.generalInfo.currency.code;
    },
    getChildById: (state) => (id) => state.product.children.find((child) => child.id === id),
    getCurrencyCode: (state) => (id) => state.currencies.find((currency) => currency.id === id)?.code || "",
    getCurrencies: (state) => state.currencies,
    mode: (state) => state.mode,
    getInfoForCreate: (state) => {
      const {
        generalInfo,
        description,
        medias,
        options,
        addressData,
        children,
        addons,
        cancellationPolicy,
        checkInOut,
        welcomeLatter
      } = state.product;
      return {
        translations: [
          {
            small_description: generalInfo.subtitle,
            title: generalInfo.title,
            full_description: description,
            locale: "en"
          }
        ],
        phone: generalInfo.phone,
        country_code: generalInfo.countryCode,
        email: generalInfo.email,
        country:  generalInfo.country ? generalInfo.country.id : null,
        currency: generalInfo.currency ? generalInfo.currency.id : null,
        categories: [
          {
            category_id: generalInfo.type ? generalInfo.type.id : null,
            is_primary: true
          }
        ],
        urlms: [
          {
            locale: "en",
            url: `/listings/${generalInfo.title.trim().replace(/\s+/g, "-").toLowerCase()}-${shortId()}`,
            title: generalInfo.title
          }
        ],
        media: medias,
        options,
        address: addressData.address,
        children: children.map((child) => ({
          ...child,
          id: undefined,
          price_settings: child.price_settings.map((setting) => ({
            ...setting,
            id: undefined
          })),
          translations: [
            {
              locale: "en",
              title: generalInfo.title
            }
          ]
        })),
        addons: addons.map((addon) => ({
          qty: addon.qty,
          price: addon.price,
          translations: {
            locale: "en",
            title: addon.title,
            description: addon.description
          }
        })),
        cancellation_policy: cancellationPolicy,
        check_in: checkInOut.checkIn,
        check_out: checkInOut.checkOut,
        emailDescription: welcomeLatter,
        location: JSON.stringify(addressData.location),
        country_id: addressData.country_id
      };
    },
    getNextStepCreate: (state) => state.createStepsCompleted.find((step) => step.completed === false).route
  },
  mutations: {
    setProduct(state, payload) {
      state.product = {
        ...initialStateProduct,
        description: payload.product.translation.full_description,
        generalInfo: {
          title: payload.product.translation.title,
          subtitle: payload.product.translation.small_description,
          url: payload.product.urlm.url,
          slug: payload.product.urlm.url.split("/")[1],
          created_at: payload.product.created_at,
          verified_at: payload.product.verified_at,
          type: {
            id: payload.product.categories[0].id,
            name: payload.product.categories[0].translation.title,
            image: `${process.env.VUE_APP_ASSETS_URL}/${payload.product.categories[0].media.media.path}`
          },
          email: payload.product.email,
          countryCode : payload.product.country_code,
          phone: payload.product.phone,
          currency: payload.product.product_currency
        },
        options: payload.product.options,
        medias: payload.product.medias
          ? payload.product.medias
              .map((media) => ({
                ...media,
                media: {
                  ...media.media,
                  path: `${process.env.VUE_APP_ASSETS_URL}/${media.media.path}`
                }
              }))
              .sort((a, b) => a.position - b.position)
          : [],
        children: payload.product.children,
        addons: payload.product.addons
          ? payload.product.addons.map((addon) => ({
              id: addon.id,
              title: addon.translation.title,
              description: addon.translation.description,
              qty: addon.qty,
              price: addon.price
            }))
          : [],
        addressData: {
          countryId: payload.product.country_ref.id,
          address: payload.product.address,
          location: [JSON.parse(payload.product.location).lng, JSON.parse(payload.product.location).lat]
        },
        cancellationPolicy: payload.product.cancellation_policy,
        checkInOut: {
          checkIn: payload.product.check_in,
          checkOut: payload.product.check_out
        },
        welcomeLatter: payload.product.emailDescription,
        settings: {
          commission: payload.product.commission,
          userId: payload.product.user_id,
          labelId: payload.product.label_id
        },
        currency: payload.product.currency
      };
    },
    setLoading(state, payload) {
      state.isLoadingProduct = payload;
    },
    setInitialState(state) {
      state.product = initialStateProduct;
    },
    setData(state, payload) {
      const skip = payload.skip ?? false;
      if (payload.field && !skip) {
        notify({
          title: "Section was successfully saved",
          type: "success"
        });
        state.product[payload.field] = payload.data;
      }
      state.createStepsCompleted = state.createStepsCompleted.map((step) => {
        if (step.field === payload.step) {
          return {
            ...step,
            completed: true
          };
        }

        return step;
      });
    },
    setAddons(state, payload) {
      const isEdit = !!state.product.addons.find((addon) => addon.id === payload.addon.id);
      if (isEdit) {
        state.product.addons = state.product.addons.map((addon) => {
          if (addon.id === payload.addon.id) {
            return {
              addon,
              ...payload.addon,
              code: state.product.generalInfo.currency.code
            };
          }

          return addon;
        });
      } else {
        state.product.addons.push(payload.addon);
      }
    },
    removeAddon(state, payload) {
      state.product.addons = state.product.addons.filter((addon) => addon.id !== payload.id);
    },
    setMedias(state, payload) {
      state.product.medias = payload
        ? payload
            .map((media) => ({
              ...media,
              media: {
                ...media.media,
                path: `${process.env.VUE_APP_ASSETS_URL}/${media.media.path}`
              }
            }))
            .sort((a, b) => a.position - b.position)
        : [];
    },
    setLoadingMedias(state, payload) {
      state.isLoadingMedias = payload;
    },
    setLocation(state, payload) {
      state.product.addressData.location = payload;
    },
    setCurrencies(state, payload) {
      state.currencies = payload;
    },
    setProductMode(state, payload) {
      state.mode = payload;
    },
    setInitialCreateState(state) {
      state.createStepsCompleted = initialCreateSteps;
    }
  },
  actions: {
    async getProduct({ commit }, payload) {
      commit("setLoading", true);
      await apolloClient
        .query({
          query: GET_PRODUCT,
          variables: {
            id: payload.id
          }
        })
        .then((data) => {
          const {
            data: { getProduct: product }
          } = data;

          commit("setProduct", { product });
        })
        .catch()
        .finally(() => {
          commit("setLoading", false);
        });
    },
    async updateProduct(context, { id, data }) {
      await apolloClient
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            id,
            data
          }
        })
        .then((data) => {
          const {
            data: { updateProduct: product }
          } = data;

          context.commit("setProduct", { product });

          notify({
            title: "Section was successfully updated",
            type: "success"
          });
        });
    },
    async updatePositions(context, variables) {
      context.commit("setLoadingMedias", true);
      await apolloClient
        .mutate({
          mutation: UPDATE_POSITION,
          variables
        })
        .then((data) => {
          const {
            data: { updatePosition }
          } = data;
          context.commit("setMedias", updatePosition);
        })
        .finally(() => {
          context.commit("setLoadingMedias", false);
        });
    }
  }
};
