<template>
  <section>
    <div>
      <div class="flex justify-between gap-4 bg-gray-50 items-center">
        <h2 class="font-semibold text-gray-900">Upcoming (and current) bookings</h2>
        <router-link
          to="/bookings"
          class="flex flex-shrink-0 items-center btn btn-xs btn-transparent
          text-highlight-600 btn-transparent">
          View all
          <ArrowSmRightIcon class="ml-1 h-4 w-4" />
        </router-link>
      </div>

      <div class="my-4 flex flex-col gap-4 rounded-lg">
        <div
          v-for="item in bookings"
          :key="item.date">
          <div class="mb-1 border-t pt-3">
            <div
              v-if="isDateToday(item.date)"
              class="flex gap-2 divide-x items-center">
              <p class="font-medium text-starryNight">
                Today
              </p>
              <p class="pl-2 text-sm font-normal text-gray-500">
                {{ dayjs(new Date()).format('ddd, MMM DD')  }}
              </p>
            </div>
            <div
              v-else-if="isDateTomorrow(item.date)"
              class="flex gap-2 divide-x items-center">
              <p class="font-medium text-starryNight">
                Tomorrow
              </p>
              <p class="pl-2 text-sm font-normal text-gray-500">
                {{ dayjs(new Date()).format('ddd, MMM DD')  }}
              </p>
            </div>
            <p v-else class="text-sm font-normal text-gray-500">
              {{ dayjs(item.date).format('ddd, MMM DD') }}
            </p>
          </div>
          <ol
            v-for="booking in item.bookings"
            :key="booking.id"
            class="leading-6 text-gray-500">
            <li class="py-2 mb-1 rounded-lg sm:flex
          hover:border-black hover:mx-1 duration-200 group">
              <router-link
                :to="{ name: 'Booking', params: { id: booking.uuid }}"
                class="flex-auto text-sm font-medium text-gray-800 sm:mt-0">
                {{ booking.customer_info.number_of_people }}
                guests at
                {{ booking.bookings[0].product.translation.title }}
              </router-link>
              <p class="flex-none text-sm sm:ml-6">
                <span>{{ booking.bookings[0].product.parent.check_in }}</span>
              </p>
            </li>
          </ol>
          <ol
            v-if="item.isTodaysDate && item.bookings.length === 0"
            class="divide-y divide-gray-200 leading-6 text-gray-500">
            <li class="py-4 sm:flex">
              <p class="mt-2 flex-auto sm:mt-0 text-sm leading-6 text-gray-500">
                Nothing on today’s schedule
              </p>
            </li>
          </ol>
        </div>
      </div>
    <!--
    <ol
      v-if="!$apollo.queries.bookingsList.loading"
      class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
      <li class="py-4 sm:flex">
        <time datetime="2022-01-17" class="w-28 flex-none">Wed, Jan 12</time>
        <p class="mt-2 flex-auto sm:mt-0">Nothing on today’s schedule</p>
      </li>
      <li v-for="booking in bookingsList.items" :key="booking.id" class="py-4 sm:flex">
        <p class="w-28 flex-none">{{ dayjs(booking.bookings[0].from_date).format('ddd, MMM DD') }}</p>
        <router-link
          :to="{ name: 'Booking', params: { id: booking.uuid }}"
          class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0 hover:underline">
          {{ booking.customer_info.number_of_people }}
          guests at
          {{ booking.bookings[0].product.translation.title }}
        </router-link>
        <p class="flex-none sm:ml-6">
          <span>{{ booking.bookings[0].product.parent.check_in }}</span>
        </p>
      </li>
    </ol>
    <div
      v-else
      class="my-7 flex justify-center">
      <LoadingSpinner />
    </div>
    -->
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';
import { ArrowSmRightIcon } from "@heroicons/vue/solid";


export default {
  name: "UpcomingBookings",
  components: { ArrowSmRightIcon },
  props: {
    bookings: {
      type: Array,
      default: ()  => []
    }
  },
  data() {
    return {
      dayjs
    }
  },
  methods: {
    isDateToday(someDate) {
      dayjs.extend(isToday);
      return dayjs(someDate).isToday();
    },
    isDateTomorrow(someDate) {
      dayjs.extend(isTomorrow);
      return dayjs(someDate).isTomorrow();
    }
  }
};
</script>
