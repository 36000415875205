import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import HostDashboard from "@/views/host-dashboard";
// eslint-disable-next-line import/no-cycle
import store from "@/store";

const hydrateProps = (route, props) => {
  Object.assign(route.meta, { props });
};

const routes = [
  /**
   * Home page
   */
  {
    path: "/",
    name: "Home",
    component: HostDashboard,
    meta: {
      title: "Test"
    }
  },
  /**
   * Bookings
   */
  {
    path: "/bookings",
    name: "Bookings",
    component: () => import(/* webpackChunkName: "booking" */ "@/views/overview/Bookings/index"),
    meta: {
      container: "ContainerFluid"
    }
  },
  /**
   * Bookings/:id
   */
  {
    path: "/bookings/:id",
    name: "Booking",
    component: () => import(/* webpackChunkName: "booking" */ "@/views/overview/Bookings/Booking"),
    meta: {
      container: "ContainerFluid"
    }
  },
  /**
   * Calendar
   */
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import(/* webpackChunkName: "calendar" */ "@/views/overview/Calendar.vue"),
    meta: {
      container: "ContainerFluid"
    }
  },
  /**
   * Listings
   */
  {
    path: "/listings",
    name: "Listings",
    components: {
      default: () => import(/* webpackChunkName: "listings" */ "@/views/listings")
    },
    meta: {
      container: "ContainerFluid"
    },
    props: {
      default: true
    }
  },
  /**
   * Listings:id
   */
  {
    path: "/listings/:id",
    name: "SingleListings",
    components: {
      default: () => import(/* webpackChunkName: "SingleListings" */ "@/views/listings/Listing.vue"),
      AsideBar: () => import(/* webpackChunkName: "sidebar" */ "@/components/Navigation/TheSideBarNav.vue")
    },
    async beforeEnter(to, from, next) {
      await store.dispatch("product/getProduct", { id: +to.params.id });
      const unitsList = store.state.product.product.children.map((child) => ({
        name: child.translation.title,
        id: child.id,
        routeName: "unit"
      }));

      hydrateProps(to, { unitsList });
      next();
    },
    props: {
      default: false,
      AsideBar: {
        subNavItems: [
          {
            name: "Listing Details",
            routeName: "general",
            activeOn: ["general"],
            active: true,
            sections: [
              {
                name: "General",
                id: "#general"
              },
              {
                name: "Description",
                id: "#description"
              },
              {
                name: "Gallery",
                id: "#gallery"
              },
              {
                name: "Amenities",
                id: "#amenities"
              },
              {
                name: "Location",
                id: "#location"
              }
            ]
          },
          {
            name: "Pricing & availability",
            routeName: "unit",
            activeOn: ["unit"]
          },
          {
            name: "Addons",
            routeName: "addonsList",
            activeOn: ["addonsList"]
          },
          {
            name: "Policies & rules",
            routeName: "policies",
            activeOn: ["policies"]
          },
          {
            name: "Info for guests",
            routeName: "info-for-guests",
            activeOn: ["info-for-guests"],
            sections: [
              {
                name: "Check in & out",
                id: "#check-in-and-out"
              },
              {
                name: "Welcome letter",
                id: "#welcome-letter"
              }
            ]
          },
          {
            name: "Settings",
            routeName: "settings",
            activeOn: ["settings"],
            sections: []
          }
        ]
      }
    },
    children: [
      {
        path: "",
        name: "general",
        component: () => import(/* webpackChunkName: "Details" */ "@/components/views/Listing/Details")
      },
      {
        path: "unit",
        name: "unit",
        component: () =>
          import(/* webpackChunkName: "SubListing" */ "@/components/views/Listing/PricingAndAvailability/SubListing")
      },
      {
        path: "addons",
        name: "addonsList",
        component: () => import(/* webpackChunkName: "Addons" */ "@/components/views/Listing/Addons")
      },
      {
        path: "policies",
        name: "policies",
        component: () => import(/* webpackChunkName: "ListingPolicies" */ "@/components/views/Listing/PoliciesAndRules")
      },
      {
        path: "info-for-guests",
        name: "info-for-guests",
        component: () => import(/* webpackChunkName: "ListingInfoForGuests" */ "@/components/views/Listing/InfoForGuests")
      },
      {
        path: "settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "ListingSettings" */ "@/components/views/Listing/Settings")
      }
    ]
  },
  /**
   * Listings-create
   */
  {
    path: "/create-listing",
    name: "CreateListing",
    components: {
      default: () => import(/* webpackChunkName: "SingleListings" */ "@/views/listings/Listing.vue"),
      AsideBar: () => import(/* webpackChunkName: "sidebar" */ "@/components/Navigation/TheSideBarNav.vue")
    },
    // async beforeEnter(to, from, next) {
    //   console.log(this.props);
    //
    //   try {
    //     console.log(this.props);
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   // console.log(this.props);
    //   // Object.assign(to.meta, { disabled: false });
    //   next();
    // },
    props: {
      default: false,
      AsideBar: () => {
        const steps = store.state.product.createStepsCompleted;
        return {
          subNavItems: [
            {
              name: "Listing Details",
              routeName: "general-create",
              activeOn: ["general-create"],
              active: true,
              sections: [
                {
                  name: "General",
                  id: "#general",
                  active: true
                },
                {
                  name: "Description",
                  id: "#description",
                  active: true
                },
                {
                  name: "Gallery",
                  id: "#gallery",
                  active: true
                },
                {
                  name: "Amenities",
                  id: "#amenities",
                  active: true
                },
                {
                  name: "Location",
                  id: "#location",
                  active: true
                }
              ]
            },
            {
              name: "Pricing & availability",
              routeName: "unit-create",
              activeOn: ["unit-create"],
              active: steps.find((step) => step.field === "addressData").completed,
              sections: [
                {
                  name: "Pricing",
                  id: "#pricing",
                  active: steps.find((step) => step.field === "addressData").completed
                },
                {
                  name: "Trip length",
                  id: "#trip-length",
                  active: steps.find((step) => step.field === "children-price").completed
                },
                {
                  name: "Guests",
                  id: "#guests",
                  active: steps.find((step) => step.field === "children-trip-length").completed
                }
              ]
            },
            {
              name: "Addons",
              routeName: "addons-create",
              activeOn: ["addons-create"],
              active: steps.find((step) => step.field === "children-guests").completed
            },
            {
              name: "Policies & rules",
              routeName: "policies-create",
              activeOn: ["policies-create"],
              active: steps.find((step) => step.field === "addons").completed
            },
            {
              name: "Info for guests",
              routeName: "info-for-guests-create",
              activeOn: ["info-for-guests-create"],
              active: steps.find((step) => step.field === "cancellationPolicy").completed,
              sections: [
                {
                  name: "Check in & out",
                  id: "#check-in-and-out",
                  active: steps.find((step) => step.field === "cancellationPolicy").completed
                },
                {
                  name: "Welcome letter",
                  id: "#welcome-letter",
                  active: steps.find((step) => step.field === "checkInOut").completed
                }
              ]
            },
            {
              name: "Complete listing",
              routeName: "complete",
              activeOn: ["complete"],
              active: steps.find((step) => step.field === "welcomeLatter").completed
            }
          ]
        };
      }
    },
    children: [
      {
        path: "",
        name: "general-create",
        component: () => import(/* webpackChunkName: "Details" */ "@/components/views/Listing/Details")
      },
      {
        path: "unit-create",
        name: "unit-create",
        component: () =>
          import(/* webpackChunkName: "SubListing" */ "@/components/views/Listing/PricingAndAvailability/SubListing")
      },
      {
        path: "addons-create",
        name: "addons-create",
        component: () => import(/* webpackChunkName: "Addons" */ "@/components/views/Listing/Addons")
      },
      {
        path: "policies-create",
        name: "policies-create",
        component: () => import(/* webpackChunkName: "ListingPolicies" */ "@/components/views/Listing/PoliciesAndRules")
      },
      {
        path: "info-for-guests-create",
        name: "info-for-guests-create",
        component: () => import(/* webpackChunkName: "ListingInfoForGuests" */ "@/components/views/Listing/InfoForGuests")
      },
      {
        path: "complete",
        name: "complete",
        component: () => import(/* webpackChunkName: "ListingInfoForGuests" */ "@/components/views/Listing/Complete")
      }
    ]
  },
  /**
   * Profile
   */
  {
    path: "/account",
    name: "Account",
    redirect: () =>
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      ({ path: "/account/profile" }),
    components: {
      default: () => import(/* webpackChunkName: "account" */ "@/views/account"),
      AsideBar: () => import(/* webpackChunkName: "sidebar" */ "@/components/Navigation/TheSideBarNav.vue")
    },
    props: {
      default: false,
      AsideBar: {
        subNavItems: [
          {
            name: "Profile",
            routeName: "profile",
            activeOn: ["profile"]
          },
          {
            name: "Address",
            routeName: "address",
            activeOn: ["address"]
          },
          {
            name: "Finance",
            routeName: "finance",
            activeOn: ["finance"]
          }
        ]
      }
    },
    children: [
      {
        path: "/account/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "@/components/views/Account/Profile")
      },
      {
        path: "/account/address",
        name: "address",
        component: () => import(/* webpackChunkName: "finance" */ "@/components/views/Account/Address")
      },
      {
        path: "/account/finance",
        name: "finance",
        component: () => import(/* webpackChunkName: "finance" */ "@/components/views/Account/Finance")
      }
    ]
  },
  /**
   * Components
   */
  {
    path: "/components",
    name: "ComponentsHub",
    components: {
      default: () => import(/* webpackChunkName: "componentshub" */ "@/views/ComponentsHub.vue")
    }
  },
  /**
   * Login
   */
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Login"),
    meta: {
      layout: "AppLayoutAuth",
      requiresUnauth: true,
      container: "ContainerFluid"
    }
  },
  /**
   * 404
   */
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404"),
    meta: {
      container: "ContainerFluid"
    }
  },
  /**
   * Catch all routs that we don't use
   */
  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 45
      };
    }

    return {
      top: 0,
      left: 0
    };
  }
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const token = Cookies.get("auth_session");
  if (to.meta.requiresUnauth) {
    if (token) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else if (token) {
    next();
  } else {
    next({ name: "Login" });
  }
});

export default router;
