import { createApp, h, provide } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Notifications from "@kyvg/vue3-notification";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  RadioGroupDescription,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from "@headlessui/vue";

import { ChevronDownIcon, XIcon, MenuIcon, MailIcon, PhoneIcon } from "@heroicons/vue/outline";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
  FilterIcon,
  PlusSmIcon,
  MinusSmIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  PlusIcon,
  CheckIcon,
  SelectorIcon,
  ExclamationIcon
} from "@heroicons/vue/solid";
import { DefaultApolloClient } from "@vue/apollo-composable";
import VueMapboxTs from "vue-mapbox-ts";
import vClickOutside from "click-outside-vue3";
import { SetupCalendar } from "v-calendar";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "@/plugins/i18n/i18n.js";
import AppLayout from "@/layouts/AppLayout";
import * as FullStory from "@fullstory/browser";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { apolloClient, apolloProvider } from "./plugins/apollo/config";

import "./styles/tailwind.css";
import "./assets/css/main.scss";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "v-calendar/dist/style.css";

FullStory.init({ orgId: "o-1AHA9Y-na1" });

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App)
});

app.use(store);
app.use(router);
app.use(i18n);
app.use(apolloProvider);
app.use(VueAxios, axios);

app.use(
  createGtm({
    id: "GTM-P93TDLC",
    compatibility: false,
    vueRouter: router
  })
);

app.use(SetupCalendar, {});

app.use(VueMapboxTs);
app.use(vClickOutside);
app.use(Notifications);

app.component("AppLayout", AppLayout);
app.component("Popover", Popover);
app.component("PopoverButton", PopoverButton);
app.component("PopoverPanel", PopoverPanel);
app.component("Disclosure", Disclosure);
app.component("DisclosureButton", DisclosureButton);
app.component("DisclosurePanel", DisclosurePanel);
app.component("RadioGroup", RadioGroup);
app.component("RadioGroupLabel", RadioGroupLabel);
app.component("RadioGroupOption", RadioGroupOption);
app.component("RadioGroupDescription", RadioGroupDescription);
app.component("Menu", Menu);
app.component("MenuItem", MenuItem);
app.component("MenuButton", MenuButton);
app.component("MenuItems", MenuItems);
app.component("Dialog", Dialog);
app.component("DialogOverlay", DialogOverlay);
app.component("DialogTitle", DialogTitle);
app.component("TransitionChild", TransitionChild);
app.component("TransitionRoot", TransitionRoot);
app.component("ChevronLeftIcon", ChevronLeftIcon);
app.component("ChevronRightIcon", ChevronRightIcon);
app.component("ChevronDownIcon", ChevronDownIcon);
app.component("XIcon", XIcon);
app.component("MenuIcon", MenuIcon);
app.component("SearchIcon", SearchIcon);
app.component("FilterIcon", FilterIcon);
app.component("PlusSmIcon", PlusSmIcon);
app.component("MinusSmIcon", MinusSmIcon);
app.component("DocumentDuplicateIcon", DocumentDuplicateIcon);
app.component("TrashIcon", TrashIcon);
app.component("PlusIcon", PlusIcon);
app.component("Combobox", Combobox);
app.component("ComboboxButton", ComboboxButton);
app.component("CheckIcon", CheckIcon);
app.component("ComboboxInput", ComboboxInput);
app.component("ComboboxLabel", ComboboxLabel);
app.component("ComboboxOption", ComboboxOption);
app.component("SelectorIcon", SelectorIcon);
app.component("ComboboxOptions", ComboboxOptions);
app.component("ExclamationIcon", ExclamationIcon);

app.component("Listbox", Listbox);
app.component("ListboxLabel", ListboxLabel);
app.component("ListboxButton", ListboxButton);
app.component("ListboxOptions", ListboxOptions);
app.component("ListboxOption", ListboxOption);

app.component("MailIcon", MailIcon);
app.component("PhoneIcon", PhoneIcon);

app.directive("role", {
  mounted(el, binding) {
    const roles = binding.value;
    const userRole = store.state.auth.user.role;
    const hasPermission = roles.includes(userRole);

    if (!hasPermission) {
      el.parentNode.removeChild(el);
    }
  }
});

Sentry.init({
  app,
  environment: process.env.VUE_APP_SENTRY_ENV,
  dsn: "https://008295be5e1843deaebf01741d9de7c8@o1059902.ingest.sentry.io/6051200",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "owayy.dashboard.com", /^\//]
    })
  ],
  tracesSampleRate: 1.0
});

app.config.globalProperties.$FullStory = FullStory;

app.mount("#app");
