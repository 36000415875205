<template>
  <div class="col-span-12">
    <div class="max-w-5xl mx-auto px-4 pb-12 py-6 sm_py-0 sm:px-6 lg:px-8">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 md:col-span-4 md:col-start-2">
          <div class="mb-6">
            <h1 class="font-medium text-2xl">Hello, {{ user.name }}!</h1>
          </div>
          <UpcomingBookings v-if="!$apollo.queries.bookingsList.loading" :bookings="groupedBookings" />
          <div v-else class="loading-state flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
            <LoadingSpinner outer-class="h-10 h-10 text-water" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import UpcomingBookings from "@/components/Widgets/UpcomingBookings";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import { GET_UPCOMING_ORDERS } from "@/graphql/orders/queries";

export default {
  name: "HostDashboard",
  components: { UpcomingBookings, LoadingSpinner },
  apollo: {
    bookingsList: {
      query: GET_UPCOMING_ORDERS,
      update: (data) => data.getUpcomingOrders,
      variables() {
        return {
          data: {
            count: 5,
            user_id: this.user.id
          }
        };
      }
    }
  },
  data() {
    return {
      dayjs
    }
  },
  computed: {
    groupedBookings(){
      const data = this.bookingsList?.items || [];
      // this gives an object with dates as keys
      const dates = data.reduce((dates, booking) => {
        const date = booking?.bookings[0].from_date.split('T')[0];
        if (!dates[date]) {
          dates[date] = [];
        }
        dates[date].push(booking);
        return dates;
      }, {});

      // Edit: to add it in the array format instead
      return Object.keys(dates).map((date) => ({
        date,
        isTodaysDate: date === dayjs(new Date()).format('YYYY-MM-DD'),
        bookings: dates[date]
      }));
    },
    ...mapState({
      user: state => state.auth.user
    })
  }
};
</script>

<style scoped></style>
