import gql from "graphql-tag";

const GET_ORDERS = gql`
  query getOrders($data: OrderFilterInput!) {
    getOrders(data: $data) {
      count
      items {
        id
        uuid
        bookings {
          from_date
          to_date
          product {
            translation {
              title
            }
            parent {
              check_in
              translation {
                title
              }
            }
          }
        }
        customer_addons {
          addon_id
        }
        customer_info {
          first_name
          last_name
          email
          tel
          number_of_people
        }
        pricing {
          code
          price
        }
        created_at
        status
      }
    }
  }
`;

const GET_ORDER = gql`
  query getOrder($payment_id: String!) {
    getOrder(payment_id: $payment_id) {
      id
      uuid
      landlord {
        id
        first_name
        last_name
        email
      }
      cancel_reason
      pricing {
        code
        symbol
        price
      }
      created_at
      status
      customer_info {
        id
        adults
        children
        infants
        first_name
        last_name
        tel
        email
        additional_info
        city
        postal_code
        region
        street
      }
      bookings {
        id
        comments {
          id
          content
          created_at
        }
        cancelled
        cancel_reason
        from_date
        to_date
        product {
          translation {
            title
          }
          parent {
            translation {
              title
            }
            check_in
            check_out
            address
            location
            phone
            urlm {
              url
            }
            media {
              media {
                filename
                path
              }
            }
          }
        }
        comments {
          content
        }
        price
      }
      customer_addons {
        qty
        price
        addon {
          translation {
            title
          }
          pricing {
            code
            symbol
            price
          }
        }
      }
      gift_card {
        code
        isPercentage
        amountToRedeem
      }
      price
      currency
    }
  }
`;

const GET_UPCOMING_ORDERS = gql`
  query getUpcomingOrders($data: ClosestOrderInput!) {
    getUpcomingOrders(data: $data) {
      count
      items {
        id
        uuid
        landlord {
          id
          first_name
          last_name
          email
        }
        cancel_reason
        pricing {
          code
          symbol
          price
        }
        created_at
        status
        customer_info {
          id
          number_of_people
          first_name
          last_name
          tel
          email
          additional_info
          city
          postal_code
          region
          street
        }
        bookings {
          id
          comments {
            id
            content
            created_at
          }
          cancelled
          cancel_reason
          from_date
          to_date
          product {
            translation {
              title
            }
            parent {
              translation {
                title
              }
              check_in
              check_out
              address
              location
              phone
              media {
                media {
                  filename
                  path
                }
              }
            }
          }
          comments {
            content
          }
          price
        }
        customer_addons {
          qty
          price
          addon {
            translation {
              title
            }
            pricing {
              code
              symbol
              price
            }
          }
        }
        gift_card {
          code
          isPercentage
          amountToRedeem
        }
      }
    }
  }
`;

export { GET_ORDERS, GET_ORDER, GET_UPCOMING_ORDERS };
