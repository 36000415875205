import { createStore } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { auth } from "@/store/modules/auth";
import { product } from "@/store/modules/product";

export default createStore({
  modules: {
    auth,
    product
  }
});
